import React, { useRef } from 'react';
// import styled from 'styled-components';
import Portal from './portal';
import { useBodyScrollLock } from '../hooks/bodyScrollLock';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import MdClose from 'react-ionicons/lib/MdClose';

const Modal = ({ children, setToggle }) => {

    const ref = useRef();
    useBodyScrollLock();
    useOnClickOutside( ref, setToggle)

    // const { children, toggle, on } = this.props
    return (
        <Portal>
            <div className="modal-wrapper fixed flex justify-center top-0 left-0 items-center z-20" style={{
                height: '100%',
                width: '100%',
                background: 'rgba(0,0,0, 0.4)',
            }}>
                <div className="p-12 bg-white z-15 w-4/5 md:w-1/2 mx-auto relative rounded fadeInUp" ref={ref}>
                    {children}
                    <button className="absolute top-0 right-0 p-4" onClick={() => setToggle(false) }>
                        <MdClose />
                    </button>
                </div>
            </div>
            <div className="modal-background" style={{
                width: '100%',
                height: '100%'
            }}></div>
            {/* <Transition
                items={on}
                unique={true}
                native
                config={config.wobbly}
                from={{ opacity: 0, bgOpacity: 0, y: 40 }}
                enter={{ opacity: 1, bgOpacity: 0.5, y: 0 }}
                leave={{ opacity: 0, bgOpacity: 0, y: -40 }}
            >
                {on => on &&
                    (styles => (
                    <ModalWrapper style={{...styles}}>
                        <ModalCard style={{
                            transform: styles.y.interpolate(y =>
                                `translate3d(0, ${y}px, 0)`, ),

                            ...styles
                        }}>
                            <CloseButton onClick={toggle}>
                                <MdClose />
                            </CloseButton>
                            <div>
                                {children}
                            </div>
                        </ModalCard>
                        <Background style={{ opacity: styles.bgOpacity }} onClick={toggle} />
                    </ModalWrapper>
                ))}
            </Transition> */}
        </Portal>
    )
}

export default Modal;

// const ModalWrapper = styled(animated.div)`
//     ${absolute({})};
//     width: 100%;
//     height: 100%;
//     /* background-color: teal; */
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;
// const Card = styled.div`
//     background: white;
//     border-radius: 5px;
//     padding: 15px;
//     z-index: 15,
//     max-width: 320px;
//     margin: 0 auto;
// `;

// const AnimCard = Card.withComponent(animated.div);

// const ModalCard = styled(AnimCard)`
//     position: relative;
//     z-index: 10;
//     min-width: 320px;
//     margin-bottom: 100px;
// `;

// const CloseButton = styled.button`
//     ${absolute({
//         y: 'top',
//         x: 'right'
//     })};
//     border: none;
//     background: transparent;
//     padding: 10px;
//     cursor: pointer;
// `;

// const Background = styled(animated.div)`
//     ${absolute({})};
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     background-color: black;
//     opacity: 0.5;
// `;
