import React from 'react'
import Container from '../components/container';


const Quote = ({ children }) => {
    return (
        <div>
            <Container>
                {children}
            </Container>
        </div>
    )
}

export default Quote;
