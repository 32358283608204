import React from 'react'
import MdPhonePortrait from 'react-ionicons/lib/MdPhonePortrait'
import MdMap from 'react-ionicons/lib/MdMap'
import MdTime from 'react-ionicons/lib/MdTime'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export const RestaurantInfo = ({ location }) => {
    return (
        <div className="restaurant-location pt-2">
            <hr className="mb-4"/>
            {location.address && (
                <p className="flex mb-2">
                    <span className="w-4 mr-4">
                        <MdMap color="#aaa" />
                    </span>
                    <strong>{location.address}</strong>
                </p>
            )}
            {location.hours && (
                <p className="flex mb-2">
                    <span className="w-4 mr-4">
                        <MdTime color="#aaa" />
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: location.hours }}></span>
                </p>
            )}
           {location.phone && (
                <p className="flex mb-2">
                    <span className="w-4 mr-4">
                        <MdPhonePortrait color="#aaa" />
                    </span>
                    <span>
                        <OutboundLink className="text-blue-600 underline" href={`tel:${location.phone}`}>{location.phone}</OutboundLink>
                    </span>
                </p>
            )}
        </div>
    )
}

export default RestaurantInfo;
