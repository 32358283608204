import React, { useContext } from 'react'
import { RestaurantContext } from '../context/restaurantContext';
import Restaurant from '../components/restaurant';

const ActiveMarkers = () => {
    const { activeMarkers, sortAlpha } = useContext( RestaurantContext );

    const sortedItems = activeMarkers.sort( (a,b) => {
        if( sortAlpha == 0) {
            return a.name < b.name ? 1 : -1;
        } else {
            return a.name > b.name ? 1 : -1;
        }
    });

    return (
        <div className="restaurant-list-wrapper" aria-live="polite">
            {sortedItems && (
                <ul className="list-none">
                    {sortedItems.map( restaurant => <Restaurant key={restaurant.name} data={ restaurant } /> )}
                </ul>
            )}
            {activeMarkers.length <= 0 && (
                <p className="text-lg font-bold text-center">
                    <span className="text-2xl">🙁</span><br/>
                    Sorry, no establishments match the selected filters.
                </p>
            )}
      </div>
    )
}

export default ActiveMarkers;
