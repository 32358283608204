import React from "react";
import { Link } from 'gatsby';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from '../components/container';
// import abductionIllustration from "../images/abduction-illustration.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
          <div className="py-24  text-center">
            <h2 className="text-2xl font-bold p-3 mb-8">
              Page not found 😕
            </h2>
            <Link to="/" className="text-blue-400 underline hover:text-blue-600">Go Back home</Link>
          </div>
      </Container>
    </Layout>
  );
}

export default NotFoundPage;
