import React, { useState, useContext, useEffect } from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import MdCompass from 'react-ionicons/lib/MdCompass'
import MdTime from 'react-ionicons/lib/MdTime'
import MdStar from 'react-ionicons/lib/MdStar';
import { RestaurantContext } from '../context/restaurantContext';
import RestaurantInfo from './restaurantInfo';
import MdInformationCircle from 'react-ionicons/lib/MdInformationCircle'
import { features } from './filters';
import RestaurantFilters from './restaurantFilters';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Restaurant = ({ data }) => {
    const { activeSelection, setCurrentSelection } = useContext( RestaurantContext );
    const [isExpanded, setExpanded] = useState( false );

    const kebabId = data.name.replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, '-')
        .toLowerCase();

    /**
     * when the activeSelection changes from context, such as when
     * a pin is clicked on the map, then we toggle the restaurant
     * info panel correspondingly
     */
    useEffect(() => {
        const isSelected = activeSelection == data;
        setExpanded( isSelected );
    }, [activeSelection])


    /**
     * Handles the button click for showing/hiding a restaurant
     * description card
     *
     * @param {event} event
     */
    const handleRestaurantClick = (event) => {
        event.preventDefault();

        setExpanded(!isExpanded);
        if( !isExpanded ) {
            setCurrentSelection( data.locations[0].address )
            trackCustomEvent({
                category: 'Restaurant Expand',
                action: 'Click',
                label:  data.name
            })
        } else {
            setCurrentSelection({});
        }
    }

    const borderColor = data.isNew ? 'border-orange-400 is-new' : 'border-blue-400';

    return (
        <div className={`restaurant-card text-left shadow-lg hover:shadow-xl ${borderColor} rounded border-l-8 p-2 px-4 mb-4 relative`}>
            {data.isNew && (
                <span className="new-flag text-orange-400 font-bold absolute top-1 mr-1 right-0 text-xs pr-2 pt-1 flex items-center">
                    <MdStar style={{ display: 'inline-block'}} color="#f6ad55" fontSize="14px" />
                    <span className="ml-1">New Listing</span>
                </span>
            )}
            <button
                className="flex w-full"
                onClick={ event => handleRestaurantClick(event) }
                aria-controls={`${data.name}-info`}
                id={kebabId}
            >
                <h3 className="md:text-lg flex text-left">
                    <strong>{data.name}</strong>
                    <div className="ml-4">
                        {isExpanded && <IosArrowUp style={{ display: 'inline-block'}} fontSize="18px" />}
                        {!isExpanded && <IosArrowDown style={{ display: 'inline-block'}} fontSize="18px" />}
                    </div>
                </h3>
            </button>
            <div className="restaurant-card-info-container" id={`${kebabId}-info`} aria-hidden={!isExpanded}>
                <div className="restaurant-card-info">
                        <ul className="list-none flex flex-wrap justify-start mt-2 mb-8 text-xs" >
                            {features.map( feature => <RestaurantFilters key={feature.feature} data={data} feature={feature} />)}
                        </ul>
                        <hr className="mb-4"/>
                        <div className="text-sm pb-2">
                            {/* {data.description && (
                                <p className="flex mb-6">
                                    <span className="w-4 mr-4">
                                        <MdInformationCircle color="#65B2ED" />
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html:data.description }}></span>
                                </p>
                            )} */}
                            {/* {data.hours && (
                                <p className="flex mb-2">
                                    <span className="w-4 mr-4">
                                        <MdTime color="#aaa" />
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: data.hours }}></span>
                                </p>
                            )} */}
                            {data.website && (
                                <p className="flex mb-2">
                                    <span className="w-4 mr-4">
                                        <MdCompass color="#aaa" />
                                    </span>
                                    <span>
                                        <OutboundLink className="text-blue-600 underline" href={data.website}>{data.website}</OutboundLink>
                                    </span>
                                </p>
                            )}
                            {data.locations.map(
                                location => <RestaurantInfo key={location.lat} location={location} />
                            )}
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Restaurant;
