import React from 'react'
import ContactForm from '../components/contactForm'
import Layout from '../components/layout'
import Container from '../components/container'

export const Contact = () => {
    // const [hasJS, setHasJS] = useState(false);

    return (
        <Layout>
            <Container>
                <div className="py-16 max-w-2xl">
                    <ContactForm hasJS={true} />
                </div>
            </Container>
        </Layout>
    )
}

export default Contact;
