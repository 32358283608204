import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import SEO from "../components/seo";
import MdRestaurant from 'react-ionicons/lib/MdRestaurant'
// import Img from 'gatsby-image'
// import Img from 'gatsby-image/withIEPolyfill'
import LogoTwitter from 'react-ionicons/lib/LogoTwitter'
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoLinkedin from 'react-ionicons/lib/LogoLinkedin'
import Container from "./container";

function Header() {

  const { site, file } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          siteTitle,
          description,
          logoImage,
        }
      }
      file(relativePath: { eq: "wood-set-table-at-restaurant.jpg" }) {
        childImageSharp {
            fluid {
              srcSet
            }
        }
      }
    }
  `);

  return (
    <header
      style={{ background: '#26404C'}}
      className="overflow-hidden relative pt-12 pb-8 md:py-16 lg:pt-12 md:pb-12">
      <img sizes="100vw" srcSet={file.childImageSharp.fluid.srcSet} className="header-image object-cover absolute w-full h-full object-center opacity-50" style={{
        top: 0
      }} />
      <Container>
          <SEO
              keywords={[`abbotsford`, `restaurants`, `covid`, `coronavirus`, `still open`]}
              title={site.siteMetadata.siteTitle}
              description={site.siteMetadata.description}
          />
          <div className="text-white items-center text-center justify-between max-w-6xl mx-auto md:py-8 relative z-10 mb-4">
              <Link to="/">
                <img srcSet={site.siteMetadata.logoImage} width="160" className="mx-auto mb-8"  />
                  <h1 className="flex lg:items-center no-underline flex-col lg:flex-row">
                      {/* <MdRestaurant color="white" fontSize="38px"/> */}
                      <span className="sr-only">
                          {site.siteMetadata.siteTitle}
                      </span>
                  </h1>
                  <h2 className="text-sm md:text-lg lg:text-2xl">{site.siteMetadata.description}</h2>
              </Link>
          </div>
          <div className="text-sm md:text-md z-10 relative md:absolute md:top-0 md:right-0 md:p-8 flex text-white items-center md:w-64">
            Share this
            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fabbyeatslocal.com" className="ml-2">
              <LogoFacebook color="white" />
            </a>
            <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fabbyeatslocal.com&text=Stay%20up%20to%20date%20-%20find%20the%20latest%20information%20on%20local%20Abbotsford%20eateries%20and%20breweries%20that%20are%20open%20for%20business%20and%20adapting%20to%20the%20times.%20" className="ml-2">
              <LogoTwitter color="white" />
            </a>
            <a href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fabbyeatslocal.com&title=Stay%20up%20to%20date%20-%20find%20the%20latest%20information%20on%20local%20Abbotsford%20eateries%20and%20breweries%20that%20are%20open%20for%20business%20and%20adapting%20to%20the%20times.%20" className="ml-2">
              <LogoLinkedin color="white" />
            </a>
          </div>
      </Container>
    </header>
  );
}

export default Header;
