import React, { useContext } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Container from '../components/container';
import Filters from '../components/filters';
import GoogleMap from '../components/map';
import { RestaurantContext } from '../context/restaurantContext';
import Bumper from '../components/bumper';
import ActiveMarkers from '../components/activeMarkers';
import Quote from '../components/quote';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';


const IndexPage = () => {
  const { activeMarkers, toggleSortAlpha, sortAlpha } = useContext( RestaurantContext );

  const { site } = useStaticQuery(graphql`
    query SiteUpdateQuery {
      site {
        siteMetadata {
          updated
        }
      }
    }
  `);

  const TotalResults = () => {
    const resultsText = activeMarkers.length == 1 ? 'result' : 'results';
    return (
      <p className="mb-4 font-bold">{activeMarkers.length} {resultsText}</p>
    )
  }

  return (
    <Layout>
      <Container>
        <div className="py-12">
          <p className="md:text-xl mb-4">
              Find information on Abbotsford eateries and breweries (those founded in B.C.).
              Visit the <a href="#contact-section" className="underline text-blue-600">related links</a> below to discover other establishments beyond the focus of this site.
          </p>
          <p className="md:text-xl mb-4">Know of a B.C.-based restaurant missing from the list? <a href="#contact-section" className="underline text-blue-600">Let us know</a></p>
          {/* <p className="md:text-xl p-4 text-white bg-blue-400 rounded leading-tight">As many establishments are opening again for dine-in, operating hours may have changed. Please visit their websites or contact them directly for current hours.</p> */}
        </div>
      </Container>
      <Filters />
      <Container>
        <div className="flex justify-between md:w-1/2">
          {activeMarkers.length > 0 && <TotalResults />}
          <p className="mr-4 text-xs">
            <button
              onClick={toggleSortAlpha}>
                Sort A - Z
                <span>
                  {sortAlpha == 0 && <IosArrowUp style={{ display: 'inline-block'}} fontSize="14px" />}
                  {sortAlpha == 1 && <IosArrowDown style={{ display: 'inline-block'}} fontSize="14px" />}
                </span>
            </button>
          </p>
        </div>
        <div className="md:grid layout-grid gap-8 pb-16">
            <ActiveMarkers site={site} />
            <div  className="map-wrapper" aria-live="polite">
                <div className="sticky map-container">
                    <GoogleMap />
                    {/* <div className="py-4">
                        <p className="mb-8 text-xs">Last update: {site.siteMetadata.updated}</p>
                    </div> */}
                    <p className="text-sm mt-8">Do you want your establishment listed? Please <a className="text-blue-600 underline" href="https://forms.gle/LzTfdcER7h3Wkcgx6">submit a request</a>.</p>
                </div>
            </div>
        </div>
      </Container>
      <div className="bg-blue-400 text-white py-16">
        <Quote>
            <p className="text-2xl md:text-center md:max-w-3xl mx-auto" style={{
                fontFamily: 'Georgia',
                fontStyle: 'italic'
            }}>
                {/* <span className="inline-block"><MdQuote color="white"/></span> */}
                By offering support during this time, you could literally <br/> save someone’s livelihood
            </p>
        </Quote>
      </div>
      <div className="bg-white py-16" id="contact-section">
        <Container>
            <p className="mb-12">
              Many locally-owned franchises such as Booster Juice, A&amp;W, Mr. Sub, and Boston Pizza also need
              support, alongside international chains who employ local people.
              Check out these related links for sites with a larger
              scope than our own.
            </p>
            <Bumper />
        </Container>
      </div>
      <div className="bg-gray-200 py-16">
        <Container>
            <h4 className="text-lg font-bold mb-4">Who made this website?</h4>
            <p className="">
                We did - Michael and Katrina. One evening, early on in these stay-at-home days, we realized our favourite neighbourhood Vietnamese restaurant
                had suspended all service. Disheartened, we began searching online for a list of places still open
                that we could support. Not finding such a list, we created one ourselves. Welcome. We hope this site
                drives additional business to these establishments. Thank you
                for continuing to support the Abbotsford eateries (&amp; breweries!) we all love.
            </p>
        </Container>
      </div>
    </Layout>
  );
}

export default IndexPage;
