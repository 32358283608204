export const restaurants = [
    {
        name: 'Brgr Brgr',
        pinTitle: 'Brgr Brgr',
        hours: `<strong>Sun - Thurs:</strong> 11am - 7:30pm <br/>
            <strong>Fri - Sat:</strong> 11am - 8:30pm`,
        isOpen: true,
        delivers: true,
        email: 'eat@brgrbrgr.ca',
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        alcohol: true,
        website: 'https://www.brgrbrgr.ca/',
        locations:[
            {
                address: '33758 Essendene Ave',
                phone: '604-746-6225',
                lat: 49.0488685,
                lng: -122.2901519,
            }
        ],
    },
    {
        name: 'Zaika Tastes of India',
        hours: `<strong>Sun - Wed:</strong> 11am - 10pm <br/>
            <strong>Thurs - Sat:</strong> open late until 11pm`,
        pinTitle: 'Zaika',
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://zaikatastesofindia.ca',
        locations:[
            {
                address: '#10 - 33555 S Fraser Way',
                phone: '604-756-0535',
                lat: 49.04952449999999,
                lng: -122.2950531,
            },
            {
                address: ' #105 - 30461 Blueridge Dr.',
                phone: '604-776-4444',
                lat: 49.0658284,
                lng: -122.3795669,
            }
        ],
    },
    {
        name: 'Browns Socialhouse',
        hours: `<strong>Mon - Fri:</strong>  11am - 12am <br/>
            <strong>Sat - Sun:</strong> 10am - 12am`,
        description: 'Now open at 50% capacity.',
        email: 'solson@brownssocialhouse.com',
        pinTitle: 'Browns Socialhouse',
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: true,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://brownssocialhouse.com',
        locations:[
            {
                address: '170-1828 McCallum Rd',
                phone: '778-548-7949',
                lat: 49.03574829999999,
                lng: -122.2922264,
            }
        ],
    },
    {
        name: 'Cactus Club Cafe',
        pinTitle: 'Cactus Club',
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.cactusclubcafe.com/location/abbotsford/',
        locations:[
            {
                address: '34650 Delair Rd.',
                phone: '778-548-7949',
                lat: 49.0354497,
                lng: -122.266567,
            }
        ],
    },
    {
        name: 'Ravens Brewing',
        hours: `<strong>Wed - Thurs:</strong> 2pm - 6pm <br/>
                <strong>Fri - Sat:</strong> 12pm - 6pm`,
        pinTitle: 'Ravens',
        description: `Curbside Cafe by @smokingguncoffee: Tues-Fri 6:30am-2pm and Sat 9am-3pm`,
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: true,
        website: 'https://www.ravens.beer/',
        locations:[
            {
                address: '2485 Townline Rd.',
                phone: '604-758-1557',
                lat: 49.0474816,
                lng: -122.3605183,
            }
        ],
    },
    {
        name: 'Field House Brewing',
        pinTitle: 'Field House Brewing',
        hours: `<strong>Daily:</strong> 12pm - 6pm`,
        description: 'Come to our contactless window for pickup. Starting on Friday May 1st, we are relaunching our Beers To You and Canteen Supply services, and will be open daily.',
        isOpen: true,
        delivers: true,
        takeout: false,
        mealPackages: true,
        provisions: true,
        curbsidePickup: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: true,
        website: 'https://fieldhousebrewing.com',
        locations:[
            {
                address: '2281 W Railway St.',
                phone: '604-776-2739',
                lat: 49.043009,
                lng: -122.281803,
            }
        ],
    },
    {
        name: 'Oldhand Coffee',
        pinTitle: 'Oldhand',
        hours: `<strong>Fri:</strong> 12pm - 2pm (pickup online orders)`,
        isOpen: true,
        delivers: false,
        takeout: false,
        mealPackages: false,
        curbsidePickup: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: false,
        website: 'https://oldhandcoffee.com',
        locations:[
            {
                address: '2617 Pauline St.',
                phone: '778-779-3111',
                lat: 49.0493921,
                lng: -122.2915255,
            }
        ],
    },
    {
        name: 'Restaurant 62',
        hours: `<strong>Tues – Sat:</strong> 4pm – 7pm`,
        pinTitle: 'Restaurant 62',
        isOpen: true,
        delivers: false,
        takeout: true,
        mealPackages: false,
        provisions: true,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://restaurant62.ca',
        locations:[
            {
                address: '110 - 33559 Marshall Rd.',
                phone: '604-855-3545',
                lat: 49.0392034,
                lng: -122.2943215,
            }
        ],
    },
    {
        name: `Brodeur’s Bistro`,
        hours: `<strong>Daily:</strong> 11:30am - 8pm`,
        pinTitle: `Brodeur’s`,
        isOpen: true,
        delivers: false,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://bbistro.ca',
        locations:[
            {
                address: '3550 Mount Lehman Rd.',
                phone: '604-746-5900',
                lat: 49.0664576,
                lng: -122.3802254,
            }
        ],
    },
    {
        name: `Nikko Sushi`,
        hours: `<strong>Daily:</strong> 11:30 - 8pm`,
        pinTitle: `Nikko`,
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://nikkosushi.ca/menu/Home',
        locations:[
            {
                address: '#12 - 33324 S Fraser Way',
                phone: '604-850-8968',
                lat: 49.04919659999999,
                lng: -122.3009481,
            }
        ],
    },
    {
        name: `Ann Maries Cafe`,
        hours: `<strong>Mon - Sat:</strong> 8am -2pm`,
        pinTitle: `Ann Maries`,
        description: 'Pre-buy meals to be delivered directly to those on the front line',
        isOpen: true,
        delivers: false,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.annmariescafe.ca/',
        locations:[
            {
                address: '33771 George Ferguson Way',
                phone: '604-852-2610',
                lat: 49.0504432,
                lng: -122.2899914,
            }
        ],
    },
    {
        name: `Afterthoughts`,
        hours: `<strong>Daily:</strong> 12pm - 9pm`,
        pinTitle: `Afterthoughts`,
        description: false,
        isOpen: true,
        delivers: false,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.afterthoughts.ca/',
        locations:[
            {
                address: '100-3050 Gladwin Rd.',
                phone: '604-850-1014',
                lat: 49.0573989,
                lng: -122.3151122,
            }
        ],
    },
    {
        name: `Once Upon A Thai`,
        hours: `<strong>Daily:</strong> 11am - 9pm`,
        pinTitle: `Once Upon A Thai`,
        description: '15% off pick-up orders; free delivery depending on price point',
        isOpen: true,
        email: 'onceuponathai33757@gmail.com',
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.onceuponathai.ca/',
        locations:[
            {
                address: '33757 S Fraser Way',
                phone: '604-744-1028',
                lat: 49.0476299,
                lng: -122.2900021,
            }
        ],
    },
    {
        name: `Bavaria Restaurant`,
        hours: `<strong>Thurs - Sun:</strong> 5pm - 8pm`,
        pinTitle: `Bavaria`,
        description: false,
        isOpen: true,
        email: 'info@thebavariarestaurant.com',
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://thebavariarestaurant.com/',
        locations:[
            {
                address: '33233 Walsh Ave.',
                phone: '604-859-3154',
                lat: 49.0497088,
                lng: -122.3036709,
            }
        ],
    },
    {
        name: `Birchwood Dairy`,
        hours: `<strong>Sun:</strong> 10am - 5pm <br/>,
            <strong>Mon - Sat:</strong> 9:30am - 5pm`,
        pinTitle: `Birchwood`,
        description: false,
        isOpen: true,
        email: 'birchwooddairy@shawbiz.ca',
        delivers: false,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.birchwooddairy.com/',
        locations:[
            {
                address: '31154A Fadden Rd.',
                phone: '604-857-1315',
                lat: 49.02312329999999,
                lng: -122.2045594,
            }
        ],
    },
    {
        name: `Chef Boss Culinary`,
        hours: `<strong>Tues - Fri:</strong> 11am - 3pm <br/>
            <strong>Sat:</strong> 11am - 2pm`,
        pinTitle: `Chef Boss`,
        description: false,
        isOpen: true,
        email: 'boss.culinary@gmail.com',
        delivers: false,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.chefbossculinary.com/',
        locations:[
            {
                address: 'Unit 2 - 31018 Peardonville Rd.',
                phone: '604-746-7611',
                lat: 49.04555879999999,
                lng: -122.364222,
            }
        ],
    },
    {
        name: `Clayburn Village Store`,
        hours: `<strong>Wed & Fri:</strong> 10am - 2pm <br/>
            <strong>Sat:</strong> 10am - 2pm`,
        pinTitle: `Clayburn Store`,
        description: 'Curbside pickup only - Saturday pickups for online and phone orders starting May 3rd.',
        isOpen: true,
        email: 'clayburn@shaw.ca',
        delivers: false,
        takeout: false,
        mealPackages: false,
        curbsidePickup: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.clayburnvillagestore.com/',
        locations:[
            {
                address: '34810 Clayburn Rd.',
                phone: '604-853-4020',
                lat: 49.081782,
                lng: -122.260827,
            }
        ],
    },
    {
        name: `C-Lovers Fish & Chips`,
        hours: `<strong>Mon - Sat:</strong> 11am - 7pm <br/>
            <strong>Sun:</strong> 11:30am - 7pm`,
        pinTitle: `C-Lovers`,
        description: false,
        email: 'brad@c-lovers.com',
        isOpen: true,
        delivers: false,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://c-lovers.com/locations/abbotsford/',
        locations:[
            {
                address: '7-32451 South Fraser Way',
                phone: '604-859-9747',
                lat: 49.0522634,
                lng: -122.3252743,
            }
        ],
    },
    {
        name: `Crossroads Restaurant`,
        hours: `<strong>Daily:</strong> 10am - 8pm`,
        pinTitle: `Crossroads`,
        description: false,
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.doordash.com/store/crossroads-restaurant-abbotsford-209482/en-US/',
        locations:[
            {
                address: '1821 Sumas Way',
                phone: '604-852-1614',
                lat: 49.035125,
                lng: -122.267983,
            }
        ],
    },
    {
        name: `Ebisu Sushi`,
        hours: `<strong>Mon - Fri:</strong> 11am - 9pm <br/>
            <strong>Sat:</strong> 12pm - 9:30pm <br/>
            <strong>Sun:</strong> 12pm - 9pm`,
        pinTitle: `Ebisu`,
        description: 'Take-out 10% off; delivery for orders over $30, within 3 km',
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.ebisuabbotsford.com/',
        locations:[
            {
                address: '105A – 2596 Mcmillan Rd.',
                phone: '604-526-4346',
                lat: 49.04882969999999,
                lng: -122.2588937,
            }
        ],
    },
    {
        name: `Freshslice Pizza`,
        hours: '',
        pinTitle: `Freshslice`,
        description: 'Frontline hospital heros eat free',
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://freshslice.com/',
        locations:[
            {
                phone: '604-853-7789',
                address: '2362 Whatcom Rd.',
                lat: 49.044612,
                lng: -122.221757,
            },
        ],
    },
    {
        name: `Gians Indian Cuisine`,
        hours: `<strong>Mon - Thurs:</strong>  12pm - 9pm <br/>
            <strong>Fri - Sun:</strong> 12pm - 10pm`,
        pinTitle: `Gians Indian`,
        description: 'Website currently under construction',
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://gians.ca/',
        locations:[
            {
                address: '31549 South Fraser Way',
                phone: '604-859-4999',
                lat: 49.04638079999999,
                lng: -122.3495698,
            }
        ],
    },
    {
        name: `Greek Islands`,
        hours: '',
        pinTitle: `Greek Islands`,
        description: false,
        isOpen: true,
        delivers: true,
        takeout: true,
        mealPackages: false,
        curbsidePickup: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://greekislandsrestaurants.com/',
        locations:[
            {
                address: '686 Langdon St.',
                phone: '604-850-1613',
                lat: 49.04894789999999,
                lng: -122.3309746,
            },
            {
                address: '33244 South Fraser Way',
                phone: '604-859-1631',
                lat: 49.0500115,
                lng: -122.303333,
            }
        ],
    },
    {
        name: `Habit Project`,
        hours: '',
        pinTitle: `Habit`,
        description: false,
        isOpen: true,
        delivers: false,
        mealPackages: true,
        curbsidePickup: true,
        takeout: false,
        isLocal: false,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://thehabitproject.ca/',
        locations:[
            {
                address: '#120 - 33780 Essendene Ave',
                phone: '604-746-5233',
                lat: 49.048806,
                lng: -122.289546,
            }
        ],
    },
    {
        name: `Himalaya Indian Cuisine`,
        hours: 'Call to confirm',
        pinTitle: `Himalaya Indian Cuisine`,
        description: false,
        email: 'himalayaindiancuisineltd@gmail.com',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://himalayacuisine.ca/',
        locations:[
            {
                address: '2607 Ware St.',
                phone: '604-851-2583',
                lat: 49.0491922,
                lng: -122.3046398,
            }
        ],
    },
    {
        name: `J's Pizza`,
        // hours: `<strong>Mon - Thurs:</strong> 10:30am - 12am <br/>
        //     <strong>Fri - Sat:</strong> 10:30am - 2am <br/>
        //     <strong>Sun:</strong> 11am - 12am`,
        pinTitle: `J's Pizza`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://jspizza.ca/',
        locations:[
            {
                address: '31940 S Fraser Way',
                phone: '604-854-3299',
                lat: 49.0479965,
                lng: -122.338916,
                hours: `<strong>Sun - Thurs:</strong> 11am - 11pm <br/>
                    <strong>Fri - Sat:</strong> 11am - 1am`

            },
            {
                address: '101-2955 Immel St.',
                phone: '604-859-3295',
                lat: 49.055425,
                lng: -122.272095,
                hours: `<strong>Sun - Thurs:</strong> 2pm - 10pm <br/>
                    <strong>Fri - Sat:</strong> 2pm - 11pm <br/>
                    (hours may fluctuate)`
            }
        ],
    },
    {
        name: `Klassic Catering`,
        hours: `Call to confirm, or email klassicmeals@gmail.com`,
        pinTitle: `Klassic Catering`,
        description: '$5 delivery, or free delivery on orders over $50. Check out our <a href="https://www.facebook.com/KlassicCatering604/" class="text-blue-600">Facebook page</a> for current meal options. New flyer each week.',
        email: 'klassicmeals@gmail.com',
        isOpen: true,
        delivers: true,
        mealPackages: true,
        curbsidePickup: true,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.klassiccatering.com/',
        locations:[
            {
                address: '#1 - 30455 Progressive Way',
                phone: '604-768-0808',
                lat: 49.04913,
                lng: -122.3795694,
            }
        ],
    },
    {
        name: `Loud Mouth Brewing Co.`,
        hours: `<strong>Wed - Sat:</strong> 3pm - 8pm <br/>
        <strong>Sun:</strong> 3pm - 5:30pm`,
        pinTitle: `Loud Mouth Brewing Co.`,
        description: '',
        email: 'nicolas@loudmouthbrewingcompany.ca',
        isOpen: true,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: true,
        website: 'http://www.loudmouthbrewingcompany.ca/',
        locations:[
            {
                address: '#140 - 2582 Mt. Lehman Rd.',
                phone: '604-809-1341',
                lat: 49.0490255,
                lng: -122.3806576,
            }
        ],
    },
    {
        name: `Maan Farms`,
        hours: '',
        pinTitle: `Maan Farms`,
        description: 'Register pick-up through website',
        email: 'info@maanfarms.com',
        isOpen: true,
        delivers: false,
        mealPackages: true,
        curbsidePickup: true,
        takeout: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.maanfarms.com/',
        locations:[
            {
                address: '790 McKenzie Rd.',
                phone: '604-864-5723',
                lat: 49.01621600000001,
                lng: -122.280064,
            }
        ],
    },
    {
        name: `Maru Sushi MK`,
        hours: `<strong>Mon:</strong> 4 - 9:30pm <br/>
            <strong>Tues - Sat:</strong> 11:30 - 9:30pm <br/>
            <strong>Sun:</strong> 4:30 - 8:30pm`,
        pinTitle: `Maru Sushi MK`,
        description: 'Register pick-up through website',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.marusushimk.com/',
        locations:[
            {
                address: '#124 - 1900 North Parallel Rd.',
                phone: '604-744-1105',
                lat: 49.0370571,
                lng: -122.2300655,
            }
        ],
    },
    {
        name: `Mama Michelle's Kitchen`,
        hours: '',
        pinTitle: `Mama Michelle's Kitchen`,
        description: '$50 minumum order for delivery, free delivery on orders over $75. Order 24 hours ahead',
        isOpen: true,
        email: 'info@mamamichelles.ca',
        delivers: true,
        mealPackages: true,
        curbsidePickup: true,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://mamamichelles.ca/',
        locations:[
            {
                address: '110-33442 South Fraser Way',
                phone: '778-856-5856',
                lat: 49.0492364,
                lng: -122.2976585,
            }
        ],
    },
    {
        name: `Meat & Bread`,
        hours: `<strong>Mon - Fri:</strong> 2pm - 4pm`,
        pinTitle: `Meat & Bread`,
        description: false,
        isOpen: true,
        delivers: false,
        mealPackages: true,
        curbsidePickup: true,
        takeout: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.meatandbread.com/testkitchen',
        locations:[
            {
                address: '2455 W Railway St.',
                lat: 49.0464345,
                lng: -122.2872863,
            }
        ],
    },
    {
        name: `Memphis Blues BBQ`,
        hours: `<strong>Mon - Thurs:</strong> 11am - 10pm <br/>
            <strong>Fri - Sat:</strong> 11am - 11pm <br/>
            <strong>Sun:</strong> 11am - 10pm`,
        pinTitle: `Memphis Blues BBQ`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.memphisbluesbbq.com/abbotsford-menu/',
        locations:[
            {
                address: '#100 High St. Mall, 3122 Mt. Lehman Rd.',
                phone: '604-381-3113',
                lat: 49.05810880000001,
                lng: -122.3806021,
            }
        ],
    },
    {
        name: `Nine Kitchen Lounge`,
        hours: `<strong>Wed - Sun:</strong> 4pm - 8pm`,
        pinTitle: `Nine Kitchen Lounge`,
        description: false,
        isOpen: true,
        email: 'ninekitchenandlounge@outlook.com',
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.ninedowntown.com/',
        locations:[
            {
                address: '2625 Montrose Ave',
                phone: '604-776-2443',
                lat: 49.0496528,
                lng: -122.2905883,
            }
        ],
    },
    {
        name: `Noodlebox`,
        hours: `<strong>Mon - Sat:</strong> (12pm - 8pm)`,
        pinTitle: `Noodlebox`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.noodlebox.ca/',
        locations:[
            {
                address: '2455 W. Railway St.',
                phone: '604-744-2447',
                lat: 49.0464344,
                lng: -122.2872863,
            }
        ],
    },
    {
        name: `Ocean Park Pizza & Steak House`,
        hours: `<strong>Daily:</strong> 4pm - 10pm`,
        pinTitle: `Ocean Park Pizza & Steak House`,
        description: 'Check Facebook for updates',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.oceanparkpizzaabbotsford.com/',
        locations:[
            {
                address: '#102 - 2596 McMillan Rd.',
                phone: '604-859-2924',
                lat: 49.048817,
                lng: -122.259798,
            }
        ],
    },
    {
        name: `Paliotti's Italian Restaurant`,
        hours: `<strong>Daily:</strong> 4pm - 10pm`,
        pinTitle: `Paliotti's Italian Restaurant`,
        description: 'Check Facebook for updates',
        isOpen: true,
        email: 'joepaliotti@hotmail.com',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://paliottisitalianrestaurants.ca/',
        locations:[
            {
                address: '33886 Essendene Ave.',
                phone: '604-855-1888',
                lat: 49.0487367,
                lng: -122.2868851,
            }
        ],
    },
    {
        name: `Pizza Garden`,
        hours: `<strong>Sun - Thurs:</strong> 11:30am - 8:15pm <br/>
            <strong>Fri - Sat:</strong> 11:30 - 9pm`,
        pinTitle: `Pizza Garden`,
        description: false,
        isOpen: true,
        email: 'contact@pizzagarden.ca',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.pizzagarden.ca/',
        locations:[
            {
                address: '2653 Trethewey St.',
                phone: '604-746-2144',
                lat: 49.0502506,
                lng: -122.3276445,
            }
        ],
    },
    {
        name: `Pollyfox`,
        hours: '',
        pinTitle: `Pollyfox`,
        description: 'Delivery on Fridays, curbside pickup on Wednesdays',
        isOpen: true,
        email: 'hello@thepollyfox.com',
        delivers: true,
        mealPackages: false,
        curbsidePickup: true,
        takeout: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.thepollyfox.com/',
        locations:[
            {
                address: '130-33780 Essendene Ave',
                phone: '604-744-1881',
                lat: 49.048806,
                lng: -122.289546,
            }
        ],
    },
    {
        name: `Spice Kitchen`,
        hours: `<strong>Daily:</strong> 11am - 10pm`,
        pinTitle: `Spice Kitchen`,
        description: false,
        isOpen: true,
        email: 'spicekitchenabby@gmail.com',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://spicekitchenabby.com/',
        locations:[
            {
                address: '100-2955 Gladwin Rd.',
                phone: '604-755-7915',
                lat: 49.05550900000001,
                lng: -122.3163369,
            }
        ],
    },
    {
        name: `Sprouted Oven`,
        hours: `<strong>Mon - Thurs:</strong> 9am - 6pm <br/>
            <strong>Fri:</strong> 9am - 5pm <br/>
            <strong>Sun:</strong> 11am - 6pm`,
        pinTitle: `Sprouted Oven`,
        description: false,
        isOpen: true,
        email: 'hello@sproutedoven.com',
        delivers: false,
        mealPackages: false,
        curbsidePickup: true,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.sproutedoven.com/',
        locations:[
            {
                address: '115-1828 McCallum Rd.',
                phone: '604-850-9674',
                lat: 49.0354871,
                lng: -122.2912345,
            }
        ],
    },
    {
        name: `Sushi Nara`,
        hours: `<strong>Mon - Sat:</strong> 11am - 9:30pm <br/>
            <strong>Sun:</strong> 11:30 - 9pm`,
        pinTitle: `Sushi Nara`,
        description: false,
        isOpen: true,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://sushinara95.com/sushi/',
        locations:[
            {
                address: '150 - 2362 Whatcom Rd.',
                lat: 49.0447493,
                lng: -122.2218459,
            },
            {
                address: '3050 Gladwin Rd.',
                lat: 49.05728609999999,
                lng: -122.3144919,
            }
        ],
    },
    {
        name: `TracyCakes`,
        hours: '',
        pinTitle: `TracyCakes`,
        description: false,
        isOpen: true,
        email: 'info@tracycakesonline.com',
        delivers: false,
        mealPackages: false,
        curbsidePickup: true,
        takeout: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://tracycakesonline.com/',
        locations:[
            {
                address: '#101 - 2636 Montrose Ave.',
                phone: '604-852-1904',
                lat: 49.0498291,
                lng: -122.2900177,
            }
        ],
    },
    {
        name: `White Spot`,
        hours: '',
        pinTitle: `White Spot`,
        description: 'Discounts available for phone and online orders',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.whitespot.ca/',
        locations:[
            {
                address: '33215 South Fraser Way',
                phone: '604-852-6676',
                lat: 49.0505972,
                lng: -122.3038505,
            }
        ],
    },
    {
        name: `Wings`,
        hours: `<strong>Daily:</strong> 12pm - 8pm`,
        pinTitle: `Wings`,
        description: false, //'1/2 price wings for health care providers',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://greatwings.ca/',
        locations:[
            {
                address: '1965 Sumas Way',
                phone: '604-756-1077',
                lat: 49.0372378,
                lng: -122.268735,
            }
        ],
    },
    {
        name: `Veerji Fish 'n' Grill`,
        hours: `<strong>Sun - Thurs:</strong> 11am - 9:30pm <br/>
            <strong>Fri - Sat:</strong> 11am - 10pm`,
        pinTitle: `Veerji Fish 'n' Grill`,
        description: 'Free food for those who lost jobs due to COVID and for the homeless',
        isOpen: true,
        email: 'info@veerji.ca',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://veerji.ca/',
        locations:[
            {
                address: '#103 - 2494 Clearbrook Rd.',
                phone: '604-776-1919',
                lat: 49.047465,
                lng: -122.336761,
            }
        ],
    },
    {
        name: `Vi-La Palace`,
        hours: `<strong>Tues - Sat</strong> 11am - 7pm`,
        pinTitle: `Vi-La Palace`,
        description: false,
        isOpen: true,
        email: 'info@veerji.ca',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.vilapalace.com/',
        locations:[
            {
                address: '#107 - 3240 Mt. Lehman Rd.',
                phone: '604-625-5555',
                lat: 49.061606,
                lng: -122.379897,
            }
        ],
    },
    {
        name: `Dragon Fort`,
        hours: `<strong>Tues - Thurs, Sun:</strong> 11:30am - 10pm <br/>
            <strong>Fri - Sat:</strong> 11:30am - 11pm
        `,
        pinTitle: `Dragon Fort`,
        description: false,
        isOpen: true,
        email: 'dragonfort2019@gmail.com',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true ,
        website: 'https://dragonfortchinesefood.com',
        locations:[
            {
                address: '2421 Pauline St.',
                phone: '604-852-6891',
                lat: 49.0460059,
                lng: -122.2920854,
            }
        ],
    },
    {
        name: `Trading Post Eatery`,
        hours: `<strong>Mon - Thurs:</strong> - 11am - 11:30pm <br/>
            <strong>Fri:</strong> 11:30 - 12am <br/>
            <strong>Sat:</strong> 11am - 12am <br/>
            <strong>Sun:</strong> 11am - 11pm`,
        pinTitle: `Trading Post Eatery`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://tradingpostbrewing.com/',
        locations:[
            {
                address: '3058 Gladwin Rd.',
                phone: '604-343-2337',
                lat: 49.05731259999999,
                lng: -122.3151949,
            }
        ],
    },
    {
        name: `Sushi Box`,
        hours: `<strong>Mon - Fri:</strong> 11:30am - 8:30pm<br/> <strong>Sat:</strong> 11:30am - 7:30pm`,
        pinTitle: `Sushi Box`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.sushiboxbc.com/',
        locations:[
            {
                address: '1528 McCallum Rd.',
                phone: '604-744-5511',
                lat: 49.0308402,
                lng: -122.2927545,
            }
        ],
    },
    {
        name: `Kojan Sushi`,
        hours: `<strong>Daily:</strong>  11am - 9:30pm <br/>
            Closed Wednesdays`,
        pinTitle: `Kojan Sushi`,
        description: false,
        isOpen: true,
        email: 'kojansushi@foodly.ca',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://kojansushi.ca/',
        locations:[
            {
                address: '#103 - 33640 South Fraser Way',
                phone: '604-776-1001',
                lat: 49.0485304,
                lng: -122.2929044,
            }
        ],
    },
    {
        name: `Dake Sushi`,
        hours: `<strong>Mon, Wed - Sat:</strong> 11:30am - 9:30pm<br/>
            <strong>Sun:</strong> 11:30am - 9pm`,
        pinTitle: `Dake Sushi`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://dakesushi.com/web/',
        locations:[
            {
                address: '2132 Clearbrook Rd.',
                phone: '604-852-8810',
                lat: 49.04082260000001,
                lng: -122.336942,
            }
        ],
    },
    {
        name: `Duft and Co.`,
        hours: '',
        pinTitle: `Duft and Co.`,
        description: 'Online ordering for contactless pick up at the Bakehouse. Select items on specific days will be available for preordering each week',
        isOpen: true,
        delivers: false,
        mealPackages: false,
        curbsidePickup: true,
        takeout: false,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.duftandco.com/shop.html',
        locations:[
            {
                address: '#103 - 2636 Montrose Ave.',
                phone: '604-744-2443',
                lat: 49.0498136,
                lng: -122.2901353,
            }
        ],
    },
    // {
    //     name: `Old Yale Brewing Co.`,
    //     hours: `<strong>Thurs - Fri:</strong> 12pm - 5pm <br/>
    //         <strong>Sat:</strong> 12pm - 4pm.`,
    //     pinTitle: `Old Yale Brewing Co.`,
    //     description: 'Tasting Room open for Beers to go',
    //     isOpen: true,
    //     email: 'info@oldyalebrewing.com',
    //     delivers: true,
    //     mealPackages: false,
    //     curbsidePickup: false,
    //     takeout: true,
    //     isLocal: true,
    //     foodPrimary: true,
    //     drinkPrimary: true,
    //     alcohol: true,
    //     website: 'https://oldyalebrewing.com',
    //     locations:[
    //         {
    //             address: '404-44550 S. Sumas Rd.',
    //             phone: '604-392-2011',
    //             lat: 49.1180015,
    //             lng: -121.9911093,
    //         }
    //     ],
    // },
    {
        name: `Hana Sushi`,
        hours: `<strong>Mon - Sun:</strong>  11am - 9pm`,
        pinTitle: `Hana Sushi`,
        description: false,
        isOpen: true,
        email: 'abbyhanasushi@gmail.com',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.hanasushibc.com/',
        locations:[
            {
                address: '#103 - 3240 Mount Lehman Rd.',
                phone: '604-626-0330',
                lat: 49.0617128,
                lng: -122.3798727,
            }
        ],
    },
    {
        name: `Sushiwa`,
        hours: `<strong>Tues - Sat:</strong> 11:30am - 9pm`,
        pinTitle: `Sushiwa`,
        description: false,
        isOpen: false,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://www.sushiwa.org/',
        locations:[
            {
                address: '#4 - 32750 George Feguson Way',
                phone: '604-755-8587',
                lat: 49.054847,
                lng: -122.317051,
            }
        ],
    },
    // {
    //     name: `Tony's Place Restaurant`,
    //     hours: ``,
    //     pinTitle: `Tony's Place Restaurant`,
    //     description: false,
    //     isOpen: false,
    //     delivers: true,
    //     mealPackages: false,
    //     curbsidePickup: false,
    //     takeout: true,
    //     isLocal: true,
    //     foodPrimary: true,
    //     drinkPrimary: true,
    //     alcohol: true,
    //     website: 'http://www.sushiwa.org/',
    //     locations:[
    //         {
    //             address: ' 31120 Peardonville Rd #101',
    //             phone: '604-755-8587',
    //             lat: 49.04568200000001,
    //             lng: -122.3623422,
    //         }
    //     ],
    // },
    {
        name: `Townhall`,
        hours: `<strong>Mon - Wed:</strong> 11am - 12am <br/>
            <strong>Thurs - Fri: </strong> 11am - 1am <br />
            <strong>Sat:</strong> 10:30am - 1am <br />
            <strong>Sun:</strong> 10am - 12pm`,
        pinTitle: `Townhall`,
        description: false,
        isOpen: true,
        email: 'townhallabbotsford@jrg.ca',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.jrghospitality.ca/establishment/townhall-public-houses/townhall-abbotsford/',
        locations:[
            {
                address: '33720 South Fraser Way',
                phone: '604-746-2000',
                lat: 49.0476502,
                lng: -122.2911626,
            }
        ],
    },
    {
        name: `Rendezvous Restaurant`,
        hours: `<strong>Daily:</strong> 3pm - 10pm`,
        pinTitle: `Rendezvous Restaurant`,
        description: false,
        isOpen: true,
        email: 'tjrestaurantltd@gmail.com',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.therendezvousrestaurant.com/',
        locations:[
            {
                address: '230 - 3033 Immel St',
                phone: '604-853-9911',
                lat: 49.05728970000001,
                lng: -122.2733373,
            }
        ],
    },
    {
        name: `Jasmine Garden`,
        hours: `<strong>Tues - Thurs, Sun:</strong> 11am - 2pm<br/>
            <strong>Fri - Sat:</strong> 11am - 2pm, 4pm - 11pm`,
        pinTitle: `Jasmine Garden`,
        description: false,
        isOpen: true,
        email: 'info@jasmine-garden.ca',
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.jasmine-garden.ca/',
        locations:[
            {
                address: '#1 - 31813 South Fraser Way',
                phone: '604-850-8628',
                lat: 49.0481559,
                lng: -122.3426432,
            }
        ],
    },
    {
        name: `Little Saigon & Little Dim Sum`,
        hours: `<strong>Daily:</strong> 11am - 8:45pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: true,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://www.littlesaigon.ca/online',
        locations:[
            {
                address: '33766 Essendene Ave',
                phone: '604-758-0888',
                lat: 49.0487445,
                lng: -122.2898127,
            }
        ],
    },
    {
        name: `Great Pizza`,
        hours: `<strong>Sun - Thurs:</strong> 11am - 12:30am <br/>
        <strong>Fri - Sat:</strong> 11am - 2:30am `,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.greatpizza.ca/',
        locations:[
            {
                address: '31831 South Fraser Way',
                phone: '604-852-0241',
                lat: 49.04838729999999,
                lng: -122.3420138,
            }
        ],
    },
    {
        name: `Dream Pizza`,
        hours: `<strong>Sun - Thurs:</strong> 11am - 9pm <br/>
            <strong>Fri - Sat:</strong> 11am - 10pm `,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.dreampizza.ca/',
        locations:[
            {
                address: '1990 McCallum Rd',
                phone: '604-746-2900',
                lat: 49.03874909999999,
                lng: -122.2929345,
            }
        ],
    },
    {
        name: `Roseland Restaurant`,
        hours: `<strong>Mon - Sat:</strong> 8:30am - 8:30pm <br/>
            <strong>Sun:</strong> 8:30am - 7:30pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://www.roselandrestaurant.com/',
        locations:[
            {
                address: '33555 South Fraser Way',
                phone: '604-852-1699',
                lat: 49.0489699,
                lng: -122.2946993,
            }
        ],
    },
    {
        name: `Patna Sweets and Restaurant`,
        hours: `<strong>Daily:</strong> 10am - 9pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.patnasweets.com/',
        locations:[
            {
                address: ' #102 - 2580 Cedar Park Pl.',
                phone: '604-756-0383',
                lat: 49.049276,
                lng: -122.335139,
            }
        ],
    },
    {
        name: `Little Japan Sushi`,
        hours: `<strong>Daily:</strong> 11:30am - 8pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://www.littlejapansushi.com/',
        locations:[
            {
                address: '33643 Marshall Rd.',
                phone: '604-854-6205',
                lat: 49.039288,
                lng: -122.2919429,
            }
        ],
    },
    {
        name: `Ono’s Sushi`,
        hours: `<strong>Daily:</strong> 11am - 8pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.sushionos.com/',
        locations:[
            {
                address: '3122 Mount Lehman Rd C180',
                phone: '604-381-3833',
                lat: 49.0582894,
                lng: -122.3790931,
            }
        ],
    },
    {
        name: `Pho Abbotsford #1 Oriental Noodle House`,
        hours: `<strong>Daily:</strong> 11am - 8pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://phoabbotsford.ca/',
        locations:[
            {
                address: '2644 Cedar Park Pl.',
                phone: '604-859-9048',
                lat: 49.0503797,
                lng: -122.3349617,
            }
        ],
    },
    {
        name: `Lullys Food Experience`,
        hours: `<strong>Daily:</strong> 11am - 3pm`,
        description: `Please check Facebook or Instagram for daily updates - operation is dependent on weather,
            and will likely be closed on very windy days.`,
        isOpen: true,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://lullys.ca/',
        locations:[
            {
                address: '32533 South Fraser Way (Canadian Tire parking lot)',
                phone: '604-859-9048',
                lat: 49.0523193,
                lng: -122.3229341,
            }
        ],
    },
    {
        name: `Midori Japanese Restaurant`,
        hours: `<strong>Daily:</strong> 11am - 10pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.midorisushi.ca/',
        locations:[
            {
                address: '31940 South Fraser Way',
                phone: '604-864-9955',
                lat: 49.0485047,
                lng: -122.3398214,
            }
        ],
    },
    {
        name: `Sehmi Sushi`,
        hours: `<strong>Mon - Sat:</strong> 11am - 10pm <br/>
             <strong>Sun:</strong> 11am - 9:30pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://sehmisushi.com/web/',
        locations:[
            {
                address: '2443 McCallum Rd',
                phone: '604-850-1242',
                lat: 49.04648359999999,
                lng: -122.2938702,
            }
        ],
    },
    {
        name: `I am Thai`,
        hours: `<strong>Mon - Sat:</strong>  11:30am - 9pm <br/>
            <strong>Sun:</strong> 4pm - 9pm`,
        description: false,
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://www.iamthairestaurant.ca/',
        locations:[
            {
                address: '33370 South Fraser Way',
                phone: '604-744-1245',
                lat: 49.04953099999999,
                lng: -122.299933,
            }
        ],
    },
    {
        name: `Bamboo Palace`,
        hours: `<strong>Sun - Thurs:</strong>  4am - 10pm <br/>
            <strong>Fri - Sat:</strong> 4pm - 11pm  <br/>
             Closed Tuesdays`,
        description: 'Free home delivery within 3 mile radius, min order $30. 10% discount on pick-up, min order $30',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://bamboopalace.ca',
        locations:[
            {
                address: '33508 South Fraser Way',
                phone: '604-853-8311',
                lat: 49.049215,
                lng: -122.295739,
            }
        ],
    },
    {
        name: `Mandarin Garden`,
        hours: `<strong>Mon, Wed, Thurs:</strong>  12pm - 7:30pm <br/>
             <strong>Fri - Sun:</strong>  11am - 7:45pm <br/>
           Closed Tues`,
        description: '10% off pick-up orders of $25 or more; delivery by restaurant now available during all hours of operation',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'http://www.mandaringarden.ca/',
        locations:[
            {
                address: '#101 - 31549 South Fraser Way',
                phone: '604-855-9888',
                lat: 49.0463038,
                lng: -122.3499206,
            }
        ],
    },
    {
        name: `Ricky's Country Restaurant`,
        hours: `<strong>Daily:</strong> 10am to 8pm`,
        description: 'We are offering 10% discount for health care workers and first responders when ID is shown.',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://rickysrestaurants.ca/locations/abbotsford-best-western/',
        locations:[
            {
                address: '32080 Marshall Rd.',
                phone: '604-854-3344',
                lat: 49.0374296,
                lng: -122.3349611,
            }
        ],
    },
    {
        name: `Old Abbey Ales`,
        hours: `<strong>Mon - Fri:</strong> 9am to 5pm <br/>
            <strong>Sat:</strong> 12pm - 3pm <br/>
            Closed Sundays`,
        description: 'For orders placed before 2pm, deliveries start after 3pm',
        isOpen: true,
        delivers: true,
        mealPackages: false,
        curbsidePickup: true,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.oldabbeyales.com/',
        locations:[
            {
                address: '30321 Fraser Hwy #1a',
                phone: '604-607-5104',
                lat: 49.05640450000001,
                lng: -122.3833501,
            }
        ],
    },
    {
        name: `The Bag Lady Lunch`,
        hours: `<strong>Wed & Fri:</strong> 10am to 6pm`,
        description: 'Order online in advance for Wednesday and Friday pickups.',
        isOpen: true,
        delivers: false,
        mealPackages: true,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://thebagladylunch.com/',
        locations:[
            {
                address: '2807 Maple St.',
                phone: '604-807-7579',
                lat: 49.052839,
                lng: -122.284182,
            }
        ],
    },
    {
        name: `Taves Estate Cidery `,
        hours: `<strong>Tues & Sat:</strong> 2pm - 4:30pm <br/>
             <strong>Thurs:</strong> 3:30pm - 6pm`,
        description: 'We are currently selling our soft and hard cider, as well as canned goods, and some vegetables.',
        isOpen: true,
        isNew: false,
        delivers: false,
        mealPackages: false,
        curbsidePickup: true,
        provisions: true,
        takeout: false,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: true,
        website: 'https://tavesestatecidery.com/',
        locations:[
            {
                address: '333 Gladwin Rd',
                phone: '604-308-3730',
                lat: 49.0088812,
                lng: -122.3161214,
            }
        ],
    },
    // {
    //     name: `Lou's Grill`,
    //     hours: `<strong>Tues & Sat:</strong> 2pm - 4:30pm <br/>
    //          <strong>Thurs:</strong> 3:30pm - 6pm`,
    //     description: false,
    //     isOpen: true,
    //     delivers: false,
    //     mealPackages: false,
    //     curbsidePickup: true,
    //     provisions: true,
    //     takeout: false,
    //     isLocal: true,
    //     foodPrimary: false,
    //     drinkPrimary: true,
    //     alcohol: true,
    //     website: 'https://lousgrill.com/',
    //     locations:[
    //         {
    //             address: '2852 Gladys Ave',
    //             phone: '604-855-8777',
    //             lat: 49.053583,
    //             lng: -122.2865229,
    //         }
    //     ],
    // },
    // {
    //     name: `The Keg`,
    //     hours: `<strong>Tues & Sat:</strong> 2pm - 4:30pm <br/>
    //          <strong>Thurs:</strong> 3:30pm - 6pm`,
    //     description: false,
    //     isOpen: true,
    //     delivers: false,
    //     mealPackages: false,
    //     curbsidePickup: true,
    //     provisions: true,
    //     takeout: false,
    //     isLocal: true,
    //     foodPrimary: false,
    //     drinkPrimary: true,
    //     alcohol: true,
    //     website: 'https://kegsteakhouse.com/en/locations/abbotsford',
    //     locations:[
    //         {
    //             address: '2142 West Railway Street',
    //             phone: '604-855-9893',
    //             lat: 49.0415907,
    //             lng: -122.2777434,
    //         }
    //     ],
    // },
     {
        name: `Cafe Amarti`,
        hours: `<strong>Wed:</strong> 10am - 2pm`,
        description: 'Coffee beans, americanos, and some baked goods available to go. Limited hours starting May 6th.',
        isOpen: true,
        isNew: false,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: false,
        website: 'http://www.cafeamarti.com/',
        locations:[
            {
                address: '#320 - 3033 Immel St.',
                phone: '604-504-0899',
                lat: 49.0575622,
                lng: -122.273856,
            }
        ],
    },
    {
        name: `Sasha sushi`,
        hours: `<strong>Tues - Sun:</strong> 11am - 8pm <br/>
            Closed Mondays`,
        description: false,
        isOpen: true,
        isNew: false,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: true,
        drinkPrimary: false,
        alcohol: false,
        website: 'http://sashasushi.com/',
        locations:[
            {
                address: '#108 - 2070 Sumas Way',
                phone: '604-852-7874',
                lat: 49.0400861,
                lng: -122.2690556,
            }
        ],
    },
    {
        name: `Cedar Park Chaat House`,
        hours: `<strong>Daily:</strong> 11am - 9pm`,
        description: false,
        isOpen: true,
        isNew: false,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: false,
        alcohol: false,
        website: 'https://cedarparkchaathouse.ca/',
        locations:[
            {
                address: '2598 Cedar Park Pl.',
                phone: '604-854-3877',
                lat: 49.0494646,
                lng: -122.3350578,
            }
        ],
    },
    {
        name: `Bow & Stern`,
        hours: `<strong>Daily:</strong> 11am - 9pm`,
        description: false,
        isOpen: true,
        isNew: false,
        delivers: true,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: false,
        alcohol: true,
        website: 'https://www.bowandstern.ca/',
        locations:[
            {
                address: '2551 Montrose Ave.',
                phone: '604-776-3474',
                lat: 49.04826569999999,
                lng: -122.2905537,
            }
        ],
    },
    {
        name: `Good Kid Coffee`,
        hours: `<strong>Mon - Sat:</strong> 7am - 5pm`,
        description: false,
        isOpen: true,
        isNew: false,
        delivers: false,
        mealPackages: false,
        curbsidePickup: false,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: false,
        website: 'https://www.goodkidcoffee.ca/',
        locations:[
            {
                address: '111-1975 Mccallum Road',
                phone: '604-758-1131',
                lat: 49.0382941,
                lng: -122.2958185,
            }
        ],
    },
    {
        name: `SippChai Cafe`,
        hours: `<strong>Tues:</strong> 9am - 8:30pm <br/>
            <strong>Wed - Fri, Mon:</strong> 9am - 10pm <br/>
            <strong>Sat - Sun:</strong> 9am - 8pm <br/>
        `,
        description: false,
        isOpen: true,
        isNew: false,
        delivers: false,
        mealPackages: false,
        curbsidePickup: true,
        provisions: false,
        takeout: true,
        isLocal: true,
        foodPrimary: false,
        drinkPrimary: true,
        alcohol: true,
        website: 'https://sippchai.com/',
        locations:[
            {
                address: '180 - 2655 Clearbrook Rd',
                phone: '604-859-0074',
                lat: 49.050454,
                lng: -122.338509,
            }
        ],
    },
]

