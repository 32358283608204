import React from 'react';
import Header from './header';
import Container from './container';
import cascadia from '../images/cascadia-logo.svg';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Layout = ({ children }) => {
  const year = new Date().getFullYear();
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-800 bg-gray-100">
        <Header />
        <main className="flex-1 w-full">
            {children}
        </main>
        <footer style={{ background: '#26404C'}}>
            <Container>
                <nav className="flex flex-col md:flex-row justify-between max-w-6xl py-8 md:pb-24 mx-auto text-sm  text-white">
                    <p className="flex justify-center items-center mb-8 md:mb-0">
                        <span className="flex align-center">Created by{` `}</span>
                        <OutboundLink
                            className="font-bold no-underline"
                            href="https://cascadia.digital"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={cascadia} alt="Cascadia Digital" width="140" className="ml-4"/>
                        </OutboundLink>
                    </p>
                    <p className="flex justify-center items-center">
                        &copy; {year} Cascadia Digital
                    </p>
                </nav>
            </Container>
        </footer>
    </div>
  );
}

export default Layout;
