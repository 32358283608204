import React from 'react';
import { RestaurantProvider } from './src/context/restaurantContext';
import "./src/css/style.css";

/**
 * See: https://www.gatsbyjs.org/docs/browser-apis/
//  */
export const wrapRootElement = ({element}) => (
    <RestaurantProvider>{element}</RestaurantProvider>
)



