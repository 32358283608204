import React, { useState, useEffect, useRef } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Modal from './Modal';
import ContactForm from './contactForm';

const Bumper = () => {
    const [hasJS, setHasJS] = useState(false);
    const [modalVisible, setModalVisible] = useState( false );
    const buttonRef = useRef();

    useEffect(() => {
        addBodyClass();
    }, [])

    const addBodyClass = () => {
        if(typeof window !== 'undefined'){
            const body = document.body;
            body.classList.add('js');
            setHasJS(true)
        }
    }

    const closeModal = () => {
        buttonRef.current.focus();
        setModalVisible(false);
    }

    return (
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <h4 className="text-lg font-bold mb-4">Related Links</h4>
                <p className="mb-4">For more information on supporting Abbotsford businesses:</p>
                <ul className="list-disc list-inside">
                    <li className="mb-2">
                        <OutboundLink className="text-blue-600 underline" href="https://caed.abbotsford.ca/covid-19-business-support/#openbusiness">Open Businesses in Abbotsford</OutboundLink>
                    </li>
                    <li className="mb-2">
                        <OutboundLink className="text-blue-600 underline" href="https://tourismabbotsford.ca/explore/eats/">Tourism Abbotsford Local Eats</OutboundLink>
                    </li>
                    {/* <li className="mb-2">
                        <OutboundLink className="text-blue-600 underline" href="http://downtownabbotsford.ca/2020/03/23/downtown-abbotsford-businesses-respond-to-covid-19/">Downtown Abbotsford Businesses Respond to COVID-19</OutboundLink>
                    </li> */}
                    {/* <li className="mb-2">
                        <OutboundLink className="text-blue-600 underline" href="https://covid19.fraservalleynow.com/open-business-directory/business-category/restaurants-food-beverages/">Fraser Valley Now Open Business Directory</OutboundLink>
                    </li> */}
                    <li className="mb-2">
                        <OutboundLink className="text-blue-600 underline" href="https://supportlocalfv.ca/">Support Local Fraser Valley</OutboundLink>
                    </li>
                </ul>
            </div>
            <div className="w-full md:w-1/2">
                <h4 className="text-lg font-bold mb-4">Staying up to date</h4>
                <p className="mb-4">
                    Consider following your favourite establishments on their social channels such as Facebook or Instagram, as many offer weekly specials.
                </p>
                <p className="mb-8">
                    Information is constantly changing in these unprecedented times. As we cannot guarantee the information presented is 100% accurate,
                    we welcome any updates from the public, including suggestions on new B.C.-based listings.</p>
                {/* {renderContactLink()} */}
                <button ref={buttonRef} className="px-8 py-2 bg-blue-400 text-white font-bold rounded shadow hover:bg-blue-500" onClick={() => setModalVisible( !modalVisible )}>Contact</button>
                {modalVisible && (
                    <Modal setToggle={closeModal}>
                        <ContactForm hasJS={hasJS} setToggle={closeModal} />
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default Bumper;
