import React from 'react'
import MdCheckmark from 'react-ionicons/lib/MdCheckmark';
import MdClose from 'react-ionicons/lib/MdClose';

const RestaurantFilters = ({ data, feature }) => {

    const getIcon = prop => {
        return prop && prop !== false
            ? <MdCheckmark style={{ display: 'inline-block' }} color="green" fontSize="16px" />
            : <MdClose style={{ display: 'inline-block' }}  color="red"  fontSize="16px" />
    }


    return (
        <li className="mb-2 mr-2">
            <div className="rounded border-navy flex items-center py-1 px-2 bg-gray-200">
                {getIcon(data[feature.feature])} <span className="ml-1">{feature.label}</span>
            </div>
        </li>
    )
}

export default RestaurantFilters;
