import React, { useContext, useState, useEffect } from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { RestaurantContext } from '../context/restaurantContext';
import MdCheckmarkCircleOutline from 'react-ionicons/lib/MdCheckmarkCircleOutline';
import MdRadioButtonOff from 'react-ionicons/lib/MdRadioButtonOff'
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import MdBulb from 'react-ionicons/lib/MdBulb';
import Container from './container';

const Filters = () => {
    const { activeFilters, addToFilters, resetFilters, setCurrentSelection, setSearchTerm } = useContext( RestaurantContext );
    const [infoItems, setInfoItems] = useState(false);
    const [localSearch, setLocalSearch] = useState('');

    const hasActiveFilters = activeFilters.length > 0;
    const checkActive = feature => activeFilters.includes(feature.feature);

    useEffect( () => {
        const infoItems = features.filter( feature => {
            return feature.hasOwnProperty('info') && activeFilters.includes(feature.feature);
        })
        .map( item => {
            return item.info;
        });
        setInfoItems( infoItems );

    }, [activeFilters])

    const reset = () => {
        setCurrentSelection({});
        resetFilters();
        trackCustomEvent({
            category: 'Reset Filters',
            action: 'Click',
            label: 'Filter Click'
        });
    }

    const handleSelectChange = value => {
        setCurrentSelection({});
        reset();
        trackCustomEvent({
            label: value,
            action: 'Select',
            category: 'Filter Select (mobile)'
        });
        if( value == 'all') {
            return;
        }
        addToFilters(value);
    }

    const handleButtonClick = feature => {
        addToFilters(feature);
        setCurrentSelection({});
        trackCustomEvent({
            label: feature,
            action: 'Click',
            category: 'Filter Click'
        });
    }

    return (
        <Container>
            <div className="filters-container mb-8">
                <div className="flex flex-col md:flex-row">
                    <div className="mb-2 md:mb-0 md:mr-4 hidden md:block">
                        <label htmlFor="search-box" className="mb-2 block">Search:</label>
                        <input
                            id="search-box"
                            type="text"
                            className="border-gray-400 border rounded p-2 mb-4 shadow"
                            placeholder="Type to search..."
                            value={localSearch}
                            onChange={ e => {
                                setLocalSearch(e.target.value)
                                setSearchTerm(e.target.value)
                            }}
                        />
                    </div>
                    <div>
                        <p className="mb-2">Filter: </p>
                        <ul className="hidden list-none md:flex flex-wrap justify-start mb-4" aria-live="polite">
                            <li className="mb-2 inline-block mr-2">
                                <button className="shadow text-left border-gray-400  border rounded p-2 px-4 w-full bg-white hover:shadow-md flex items-center" onClick={reset}>
                                    {!hasActiveFilters && <MdCheckmarkCircleOutline style={{ display: 'inline-block', marginRight: '8px' }}  fontSize="16px" />}
                                    {hasActiveFilters && <MdRadioButtonOff style={{ display: 'inline-block', marginRight: '8px' }}  fontSize="16px" />}
                                    All
                                </button>
                            </li>
                            {features && features.map( feature => {
                                const activeClass = checkActive(feature) ? 'bg-blue-400 text-white' : '';
                                return (
                                    <li className="mb-2 block mr-2" key={feature.feature}>
                                        <button
                                            onClick={() => handleButtonClick(feature.feature)}
                                            className={`shadow text-left border-gray-400 border rounded p-2 px-4 w-full ${activeClass} bg-white hover:shadow-md flex items-center`}>
                                            {checkActive(feature) && <MdCheckmarkCircleOutline style={{ display: 'inline-block', marginRight: '8px' }}  fontSize="16px" color="white" />}
                                            {!checkActive(feature) && <MdRadioButtonOff style={{ display: 'inline-block', marginRight: '8px' }}  fontSize="16px" />}
                                            {feature.buttonText}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="md:hidden flex relative mb-4">
                            <select
                                onChange={(e) => handleSelectChange(e.target.value)}
                                className="p-2 border bg-white w-full">
                                <option value="all">All</option>
                                {features && features.map( feature => {
                                    return (
                                        <option className="mb-2 block mr-2" key={feature.feature} value={feature.feature}>
                                            {feature.buttonText}
                                        </option>
                                    )
                                })}
                            </select>
                            <span className="select-arrow absolute pointer-events-none z-10">
                                <IosArrowDown />
                            </span>
                        </div>
                    </div>
                </div>
                {infoItems && (
                    <p className="text-sm flex">
                        {renderHelperText(infoItems)}
                    </p>
                )}
            </div>
        </Container>
    )
}

const renderHelperText = infoItems => {

    if( infoItems.length > 0 ) {
        return (
            <>
                <span className="mr-2">
                    <MdBulb fontSize="20px" />
                </span>
                {infoItems.join(' ')}
            </>
        )
    }
}


export const features = [
    {
      feature: 'delivers',
      buttonText: 'Delivery',
      info: 'Delivery services may be available through either the establishment itself or through a third party such as DoorDash or Skip the Dishes.',
      label: 'Delivery'
    },
    {
      feature: 'takeout',
      buttonText: 'Takeout',
      label: 'Takeout'
    },
    {
      feature: 'alcohol',
      buttonText: 'Alcohol',
      label: 'Alcohol'
    },
    // {
    //     feature: 'drinkPrimary',
    //     buttonText: 'Drink Primary'
    // },
    {
        feature: 'curbsidePickup',
        buttonText: 'Curbside Pickup',
        info: 'Some establishments not offering takeout have set limited hours allowing for a pre-arranged pick-up of items such as baked goods, alcohol, or meal kits.',
        label: 'Curbside Pickup'

    },
    {
        feature: 'mealPackages',
        buttonText: 'Meal Kits',
        info: 'Many companies are innovating at this time to try and stay afloat. Consider buying a meal kit which may be a freezer meal or one intended for immediate consumption.',
        label: 'Meal Kits'
    },
    {
        feature: 'provisions',
        buttonText: 'Provisions',
        info: '"Provisions" is an emerging category, and could mean farm fresh produce, specialty sauces, jams, broths, ice cream, etc.',
        label: 'Provisions'
    },

  ]

export default Filters;
