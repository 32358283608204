import React, { createContext, useState, useEffect } from 'react';
import { restaurants as data } from '../data.js';

// sort the data alphabetically
const alphabeticalData = data.sort( (a,b) => {
    return a.name > b.name ? 1 : -1;
})

const defaultContext = {
    markers: alphabeticalData, ///.map( data => data.locations),
    activeFilters: [],
    activeSelection: {},
    setActiveSelection: () => { console.log('set active selection')},
    addToFilters:  () => { console.log('set active filters')},
    resetFilters: () => { console.log('reset filters')},
    setSortAlpha: () => { console.log('toggle sort')}
}

export const RestaurantContext = createContext( defaultContext );

export const RestaurantProvider = ({ children }) => {
    const [activeMarkers, setActiveMarkers] = useState(defaultContext.markers);
    const [activeFilters, setActiveFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeSelection, setActiveSelection] = useState({});
    const [activeSelectionLocationIndex, setActiveSelectionLocationIndex] = useState(0);
    const [sortAlpha, setSortAlpha] = useState(1);

    /**
     * This fuction is exposed to the Provider as a wrapper
     * for the setActiveFilters state method
     *
     * @param {string} val
     */
    const addToFilters = val => {
        if ( !activeFilters.includes( val )) {
            setActiveFilters(activeFilters => [...activeFilters, val] );
        } else {
            const newActiveFilters = activeFilters.filter( item => item !== val)
            setActiveFilters( newActiveFilters );
        }
    }

    /**
     * Helper funcction to reset active filters
     */
    const resetFilters = () => setActiveFilters([]);


    /**
     * get the active markers whenever
     * the active filters change
     */
    useEffect( () => {
        getActiveMarkers();
    }, [activeFilters, searchTerm])


    /**
     * Called by useEffect when the active filters cheange,
     * we filter the restarurants by the filter data in
     * the activeFilters array from state, calls the
     * setActiveMarkers method
     */
    const getActiveMarkers = () => {
        if( !activeFilters && searchTerm == '') {
            setActiveMarkers(defaultContext.markers);
            return;
        }

        // filter and sort the new markers
        const newActiveMarkers = alphabeticalData.filter( marker => {
          return activeFilters.every( key => {
            return marker[key] == true;
          })
        })

        // only filter by search if more than 3 letters in search field
        if( searchTerm.length >= 3 ) {
            const termFilteredMarkers = newActiveMarkers.filter( marker => {
                const lowecaseName = marker.name.toLowerCase();
                return lowecaseName.includes(searchTerm);
            });
            setActiveSelection({});
            setActiveMarkers( termFilteredMarkers );

        } else {
            setActiveMarkers( newActiveMarkers );
        }
    };


    /**
     * Toggle the alphabetical sorting
     */
    const toggleSortAlpha = () => {
        const newSort = sortAlpha == 0 ? 1 : 0;
        setSortAlpha(newSort);
    }


    /**
     * calls the setActiveSelection method to set state,
     * for determining which pin to "activate". It
     * matches the address param to one of the restaurant's
     * array of locations to activate that restaurant
     *
     * @param {string} address
     */
    const setCurrentSelection = address => {
        let index = false
        const newActiveRestaurant = alphabeticalData.find( rest => {
            index = rest.locations.findIndex( loc => loc.address == address );
            if( index >= 0 ) {
                setActiveSelectionLocationIndex(index);
                return true;
            }
            // return rest.locations.some( loc =>  loc.address == address );
        });
        setActiveSelection( newActiveRestaurant );
    }

    return (
        <RestaurantContext.Provider value={{
            ...defaultContext,
            activeFilters,
            addToFilters,
            activeMarkers,
            resetFilters,
            activeSelection,
            setCurrentSelection,
            activeSelectionLocationIndex,
            setSearchTerm,
            toggleSortAlpha,
            sortAlpha
        }}>
            {children}
        </RestaurantContext.Provider>
    )
}
