import { useLayoutEffect } from 'react';

function useBodyScrollLock() {
    useLayoutEffect( () => {
        document.body.style.overflow = 'hidden';

        return function cleanup() {
            document.body.style.overflow = '';
        }
    }, []);

}

export { useBodyScrollLock };
