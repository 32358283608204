import { Component } from 'react';
import ReactDOM from 'react-dom';

// if typeof window !== `undefined`

const portalRoot = typeof window !== `undefined` ? document.getElementById('portal') : null

export default class Portal extends Component {
    constructor(){
        super();
        this.el = document.createElement('div');
    }

    componentDidMount() {
        if ( portalRoot ) {
            portalRoot.appendChild(this.el);
        }
    }


    componentWillUnmount() {
        portalRoot.removeChild(this.el);
    }

    render() {
        const { children } = this.props;
        return ReactDOM.createPortal( children, this.el );
    }
}
