import React from 'react'
import MdPin from 'react-ionicons/lib/MdPin';

const MapPin = ({ isActive, pin, mapConfig }) => {
    const { pinTitle, address, name } = pin;
    const color = isActive ? mapConfig.activeColor : mapConfig.color;
    return (
        <div>
            {isActive && (
                <span
                     style={{
                        position: 'absolute',
                        top: '-95px',
                        left: '-80px',
                        width: 160,
                        textAlign: 'center',
                        padding: '4px',
                        borderRadius: '3px',
                        background: 'white',
                        zIndex: 15,
                        minHeight: 45,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    className="shadow-md"
                >
                    <strong>{name}</strong><br/>
                    {address}
                </span>
            )}
            <MdPin fontSize="48px" color={color} style={{
                position: 'absolute',
                textAlign: 'center',
                transform: 'translate(-50%, -100%)',
                zIndex: isActive ? 10 : 0,
                filter: 'drop-shadow(4px -2px 2px rgba(0,0,0, 0.3)'
            }} />
        </div>
    )
}

export default MapPin;
