import React, { useState, useRef, useEffect } from 'react'
import MdCheckmark from 'react-ionicons/lib/MdCheckmark';

export const ContactForm = ({ hasJS, setToggle }) => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [response, setResponse] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const emailInput = useRef();

    if( !hasJS) {
        return (
            <p>You need JavaScript enabled to submit the form.</p>
        )
    }

    useEffect(() => {
        if( emailInput ) {
            emailInput.current.focus()
        }
    }, [emailInput])


    const encode = (data) => {
        return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log(e.target.extra.value);
        const { name, email, message, extra } = e.target.elements;

        if( !email.value || !message.value) {
            console.error('not allowed')
            setError('Form cannot be empty');
            return;
        }

        if( extra && extra.value !== '') {
            console.error('not allowed')
            setError('Nope, cannot submit')
            return;
        }

        try {
            setIsLoading(true);

            const response = await fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': e.target.getAttribute('name'),
                    'email': email.value,
                    'message': message.value,
                    'name': name.value
                }),
            })
            // console.log(response);

            setIsLoading(false);
            setResponse('Thanks for getting in touch!');

        } catch (error) {
            setIsLoading(false)
        }

    }


    return (
        <div>
            {!response && !isLoading && (
                <div>
                    <h3 className="mb-4 text-xl">Let us know if you have any updates for us.</h3>
                    <p className="mb-4">Please include as much information as possible on any recommendations you may have.</p>
                    <hr className="mb-4"/>
                    <form netlify data-netlify="true" onSubmit={e => handleSubmit(e)} name="contact"  data-netlify-honeypot="name">
                        <input type="text" className="ohno" name="name" id="name" autoComplete="off" />
                        <input type="text" className="ohno" name="extra" id="extra" autoComplete="off" />
                        <div className="form-group mb-2 flex flex-col">
                            <label className="flex flex-col">
                                <span>Email</span>
                                <input
                                    type="email"
                                    ref={emailInput}
                                    required
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={ e => setEmail(e.target.value)}
                                    className="border-gray-400 border rounded p-2 mb-4 shadow"
                                    placeholder="your@email.com"
                                />
                            </label>
                        </div>
                        <div className="form-group flex flex-col mb-2">
                            <label htmlFor="message">Message</label>
                            <textarea
                                required
                                name="message"
                                id="message"
                                rows="10"
                                value={message}
                                onChange={ e => setMessage(e.target.value)}
                                className="border-gray-400 border rounded p-2 mb-4 shadow h-32"
                            >

                            </textarea>
                        </div>
                        <div className="form-group">
                            <button className="px-8 py-2 bg-blue-400 text-white font-bold rounded shadow hover:bg-blue-500" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            )}
            {isLoading && (
                <div className="loading text-center py-24">
                    Submitting...
                </div>
            )}
            {response && !isLoading && (
                <div className="response text-center py-24">
                    <p className="pb-8 flex flex-col justify-center">
                        <MdCheckmark color="green" fontSize="32px" />
                        <span className="mt-4">{response}</span>
                    </p>
                    {setToggle && (
                        <p>
                            <button
                                className="px-8 py-2 bg-blue-400 text-white font-bold rounded shadow hover:bg-blue-500"
                                onClick={() => setToggle(false)}
                            >
                                Close
                            </button>
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

export default ContactForm;
