import React, { useContext, useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react';
import { RestaurantContext } from '../context/restaurantContext';
import MapPin from './mapPin';

const mapConfig = {
    center: {
        lat: 49.04286177239428,
        lng: -122.29474073547362
    },
    zoom: 13,
    color: '#26404C',
    activeColor: '#65B2ED' //'#9BD3F5'
}


const GoogleMap = () => {
    const [center, setCenter] = useState(mapConfig.center);
    const [map, setMap] = useState({});
    const [pins, setPins] = useState([]);
    const [zoom, setZoom] = useState(15);
    const { activeMarkers,
        activeSelection,
        activeFilters,
        setCurrentSelection,
        activeSelectionLocationIndex
    } = useContext(RestaurantContext);

    const createPins = () => {
        const newPins = activeMarkers.flatMap( marker => {
            const { pinTitle, name } = marker;
            const locations = marker.locations.map( location => {
                return {
                    ...location,
                    pinTitle,
                    name
                }
            })
            return locations;
        });

        setPins( newPins );
    }

    // Re-center map when resizing the window
    const bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };

    // Return map bounds based on list of places
    const getMapBounds = (map, maps, pins) => {
        const bounds = new maps.LatLngBounds();

        activeMarkers.forEach( place => {
            bounds.extend(new maps.LatLng(
                place.locations[0].lat,
                place.locations[0].lng,
            ));
        });
        return bounds;
    };

    const apiIsLoaded = (map, maps, pins) => {
        // Get bounds by our places
        const bounds = getMapBounds(map, maps, pins);
        map.fitBounds(bounds);
        map.setOptions({ minZoom: 10, maxZoom: 16 });

        setMap({ map, maps, activeMarkers });

        // Bind the resize listener
        bindResizeListener(map, maps, bounds);

        createPins();
    };

    useEffect(() => {
        // wait until the component mounts before we create the pins
        createPins();

        // reset the center when we select a restaurant,
        // and zoom into it
        if(activeSelection && activeSelection.locations) {
            // console.log('1')
            // places with multiple locations, such as J's pizza
            if( activeSelection.locations.length > 1) {
                // console.log(pins);
                const bounds = getMapBounds(map.map, map.maps, pins);
                map.map.fitBounds( bounds );
            } else {
                const { lat, lng } = activeSelection.locations[activeSelectionLocationIndex];
                setCenter({ lat, lng });
                map.map.setZoom(15);
            }
        } else {

            // no active selection
            // if( map && map.map ) {
            //     console.log('2')
            //     const bounds = getMapBounds(map.map, map.maps, pins);
            //     map.map.fitBounds( bounds );
            //     map.map.setZoom(mapConfig.zoom);
            // }
        }

        // reset zoom if no filters chosen
        if( !activeFilters.length && !activeSelection ) {
            // console.log('2')
            if( map && map.map ) {
                const bounds = getMapBounds(map.map, map.maps, pins);
                map.map.fitBounds( bounds );
            }
        }

        // reset zoom if filters chosen
        if( activeFilters.length && !activeSelection ) {
            // console.log('3')
            if( map && map.map ) {
                const bounds = getMapBounds(map.map, map.maps, pins);
                map.map.fitBounds( bounds );
            }
        }

        // when filters change
        if( !activeMarkers.length ) {
            if( map && map.map ) {
                const { center, zoom } = mapConfig;
                map.map.setCenter({ lat: center.lat, lng: center.lng });
                map.map.setZoom(zoom);
            }
        }

    }, [activeSelection, activeMarkers, activeFilters])

    return (
        <div className="h-64 w-full map-component shadow-md overflow-hidden">
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_KEY }}
                defaultCenter={mapConfig.center}
                center={{
                    ...center
                }}
                defaultZoom={mapConfig.zoom}
                zoom={zoom}
                resetBoundsOnResize={true}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, activeMarkers)}
                onChildClick={(data) => {
                    setCurrentSelection(data);
                }}>
                {pins && pins.map( pin => {
                    const isActive = activeSelection && activeSelection.name == pin.name;
                    return (
                        <MapPin
                            isActive={isActive}
                            lat={pin.lat}
                            lng={pin.lng}
                            pin={pin}
                            key={pin.address}
                            mapConfig={mapConfig}
                        />
                    )}
                )}
            </GoogleMapReact>
        </div>
    )
}

export default GoogleMap;
