import React from 'react'

const Container = ({ children }) => {
    return (
        <div className="flex-1 w-full max-w-6xl px-4 mx-auto md:px-8">
            {children}
        </div>
    )
}

export default Container;
